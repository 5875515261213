import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ModalType } from "constants/modalType";
import Autosave from "modals/Autosave";
import AddFunds from "./AddFunds";
import CreateTrip from "./CreateTrip";
import CreateVault from "./CreateVault";
import ProfileModal from "./ProfileModal";
import { useQuery } from "@apollo/client";
import { LOAD_PROFILE_INFO } from "GraphQL/Queries";
import Toast, { warningToast } from "components/toast/toast";
import CustomTripRequest from "./CustomTripRequest";
import AddEarning from "./AddEarning";
import SocialMedia from "./SocialMedia";
import AddTripVault from "./AddTripVault";
import FindAPackage from "./FindAPackage";
import { logoutUser } from "utils/helpers";
import IntAddFunds from "./IntAddFunds";
import RedeemCoupon from "./AddCoupon";

const Modal = ({
  modalType,
  setModalType,
  showModal,
  users,
  currentAccount,
  setShowModal,
  submitting,
  debitWallet,
  customTrips,
  details,
  loadCardDetails,
  tripVaults,
  exactTrip,
  setExactTrip,
  vaultTrigger,
  setVaultTrigger,
  triggerVault,
  handleProfileSetings,
  autosaveState,
  minimum_travellers,
  number_travellers,
  rate_info,
  singlePackageInfo,
  payAll,
}) => {
  const navigate = useNavigate();
  const [savingFrequency, setSavingFrequency] = useState("");
  const [user, setUser] = useState([]);

  const response = useQuery(LOAD_PROFILE_INFO);
  const { firstName, lastName, email, id, phoneNumber } = user;

  useEffect(() => {
    if (response.data) {
      setUser(response.data.me.user);
    } else if (
      response.observable.last.error?.networkError?.statusCode == 401
    ) {
      logoutUser(navigate);
      warningToast(
        "Warning",
        response.observable.last.error?.networkError?.statusCode
      );
    }
  }, [response.data, response]);

  if (showModal) {
    modalType === ModalType.PROFILE
      ? (document.body.style.overflow = "scroll")
      : (document.body.style.overflow = "hidden");
  } else {
    document.body.style.overflow = "scroll";
  }

  const handleCloseModal = () => {
    setShowModal(!showModal);
  };

  const handleBackdropClose = (e) => {
    const modal = document.getElementById("modal-backdrop");

    if (e.target === modal) {
      if (showModal) {
        setShowModal(!showModal);
      }
    }
  };

  const handleDateClick = (e) => {
    e.target.type = "date";
  };

  const accType = sessionStorage.getItem("account_type");

  return (
    <>
      {showModal && (
        <div
          id="modal-backdrop"
          className={` absolute w-[100%] h-[100%] top-0 left-0 z-[1000] grid place-content-center ${
            modalType !== ModalType.PROFILE ? "bg-black-tranparent" : ""
          }`}
          onClick={handleBackdropClose}
        >
          <div className="z-50">
            <Toast />
          </div>

          {modalType === ModalType.CREATEVAULT ? (
            <div className="modal_scroll fixed sm:bottom-1/2 bottom-0 left-1/2 sm:translate-y-2/4 -translate-x-2/4 box-border w-full sm:w-[600px] max-h-[calc(100%_-_100px)] overflow-y-auto bg-white-100 py-10 px-6 sm:rounded-lg rounded-t-lg xsm:px-12 z-40">
              <CreateVault
                showModal={showModal}
                onClose={handleCloseModal}
                singlePackageInfo={singlePackageInfo}
                handleBackdropClose={handleBackdropClose}
                modalType={modalType}
                setModalType={setModalType}
                savingFrequency={savingFrequency}
                setSavingFrequency={setSavingFrequency}
                vaultTrigger={vaultTrigger}
                setVaultTrigger={setVaultTrigger}
                triggerVault={triggerVault}
              />
            </div>
          ) : modalType === ModalType.ADDTRIPVAULT ? (
            <div className="modal_scroll fixed sm:bottom-1/2 bottom-0 left-1/2 sm:translate-y-2/4 -translate-x-2/4 box-border w-full sm:w-[600px] max-h-[calc(100%_-_100px)] overflow-y-auto bg-white-100 py-10 px-6 sm:rounded-lg rounded-t-lg xsm:px-12 z-40">
              <AddTripVault
                showModal={showModal}
                onClose={handleCloseModal}
                handleBackdropClose={handleBackdropClose}
                modalType={modalType}
                setModalType={setModalType}
                savingFrequency={savingFrequency}
                setSavingFrequency={setSavingFrequency}
                vaultTrigger={vaultTrigger}
                setVaultTrigger={setVaultTrigger}
                triggerVault={triggerVault}
                minimum_travellers={minimum_travellers}
                number_travellers={number_travellers}
                rate_info={rate_info}
                singlePackageInfo={singlePackageInfo}
                payAll={payAll}
              />
            </div>
          ) : modalType === ModalType.FINDAPACKAGE ? (
            <div className="modal_scroll fixed sm:bottom-1/2 bottom-0 left-1/2 sm:translate-y-2/4 -translate-x-2/4 box-border w-full sm:w-[600px] max-h-[calc(100%_-_100px)] overflow-y-auto bg-white-100 py-10 px-6 sm:rounded-lg rounded-t-lg xsm:px-12 z-40">
              <FindAPackage
                showModal={showModal}
                onClose={handleCloseModal}
                handleBackdropClose={handleBackdropClose}
                modalType={modalType}
                setModalType={setModalType}
              />
            </div>
          ) : modalType === ModalType.ADDFUNDS_WALLET ||
            modalType === ModalType.ADDFUNDS_VAULT ? (
            <div className="modal_scroll fixed sm:bottom-1/2 bottom-0 left-1/2 sm:translate-y-2/4 -translate-x-2/4 w-full sm:w-[450px] max-h-[calc(100%_-_100px)] overflow-y-auto bg-white-100 py-10 px-6  sm:rounded-[8px] rounded-t-lg xsm:px-12 z-40">
              {accType?.includes("NIGERIAN") ? (
                <AddFunds
                  onClose={handleCloseModal}
                  handleBackdropClose={handleBackdropClose}
                  modalType={modalType}
                  handleCloseModal={handleCloseModal}
                  showModal={showModal}
                  setShowModal={setShowModal}
                  currentAccount={currentAccount}
                  tripVaults={tripVaults}
                  loadCardDetails={loadCardDetails}
                  firstName={firstName}
                  lastName={lastName}
                  email={email}
                  phoneNumber={phoneNumber}
                  id={id}
                  exactTrip={exactTrip.trip}
                  setExactTrip={setExactTrip}
                  triggerVault={triggerVault}
                  payAll={payAll}
                />
              ) : (
                <IntAddFunds
                  onClose={handleCloseModal}
                  handleBackdropClose={handleBackdropClose}
                  modalType={modalType}
                  handleCloseModal={handleCloseModal}
                  showModal={showModal}
                  setShowModal={setShowModal}
                  currentAccount={currentAccount}
                  tripVaults={tripVaults}
                  loadCardDetails={loadCardDetails}
                  firstName={firstName}
                  lastName={lastName}
                  email={email}
                  phoneNumber={phoneNumber}
                  id={id}
                  exactTrip={exactTrip.trip}
                  setExactTrip={setExactTrip}
                  triggerVault={triggerVault}
                  payAll={payAll}
                />
              )}
            </div>
          ) : modalType === ModalType.PROFILE ? (
            <div className="">
              <ProfileModal
                onClose={handleCloseModal}
                firstName={firstName}
                lastName={lastName}
                email={email}
                phoneNumber={phoneNumber}
                id={id}
                logout={() => logoutUser(navigate)}
                onClick={handleCloseModal}
                handleBackdropClose={handleBackdropClose}
                handleProfileSetings={handleProfileSetings}
              />
            </div>
          ) : modalType === ModalType.PLANTRIP ? (
            <div className="modal_scroll fixed sm:bottom-1/2 bottom-0 left-1/2 sm:translate-y-2/4 -translate-x-2/4 box-border sm:w-[900px] w-full sm:max-w-[calc(100%_-_120px)] max-h-[calc(100%_-_100px)] sm:rounded-none rounded-t-lg z-40 ">
              <CreateTrip
                onClose={handleCloseModal}
                handleDateClick={handleDateClick}
              />
            </div>
          ) : modalType === ModalType.ADD_EARNING ? (
            <div className="modal_scroll fixed top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4 box-border w-[900px] sm:max-w-[calc(100%_-_120px)] max-w-[calc(100%_-_40px)] z-40 my-2">
              <AddEarning
                submitting={submitting}
                debitWallet={debitWallet}
                onClose={handleCloseModal}
              />
            </div>
          ) : modalType === ModalType.ADD_COUPON ? (
            <div className="modal_scroll fixed top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4 box-border w-[900px] sm:max-w-[calc(100%_-_120px)] max-w-[calc(100%_-_40px)] z-40 my-2">
              <RedeemCoupon onClose={handleCloseModal} />
            </div>
          ) : modalType === ModalType.SOCIAL_MEDIA ? (
            <div className="modal_scroll fixed top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4 box-border w-[900px] sm:max-w-[calc(100%_-_120px)] max-w-[calc(100%_-_40px)] z-40 my-2">
              <SocialMedia details={details} onClose={handleCloseModal} />
            </div>
          ) : modalType === ModalType.CUSTOM_TRIP_REQUEST ? (
            <div className="modal_scroll fixed top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4 box-border w-[900px] sm:max-w-[calc(100%_-_120px)] max-w-[calc(100%_-_40px)] z-40 my-2">
              <CustomTripRequest
                user={users}
                customTrips={customTrips}
                onClose={handleCloseModal}
              />
            </div>
          ) : (
            modalType === ModalType.AUTOSAVE && (
              <div className="modal_scroll fixed sm:bottom-1/2 bottom-0 left-1/2 sm:translate-y-2/4 -translate-x-2/4 w-full sm:w-[600px] max-h-[calc(100%_-_100px)] overflow-y-hidden bg-white-100 py-10 px-6 sm:rounded-[8px] rounded-t-lg xsm:px-12 z-40">
                <Autosave
                  autosaveState={autosaveState}
                  exactTrip={exactTrip}
                  handleClick={handleCloseModal}
                  loadCardDetails={loadCardDetails}
                  setModalType={setModalType}
                />
              </div>
            )
          )}
        </div>
      )}
    </>
  );
};

export default Modal;
